<!-- 更换手机号 -->
<template>
  <div class="changePhone">
    <header>
      <Button size="small" type="warning" @click="$router.back()">
        <Icon type="ios-arrow-back" />
        返回
      </Button>
      <Divider type="vertical" />
      <p style="font-size:18px">{{this.$route.meta.title}}</p>
    </header>
    <section>
      <Form :model="formItem" :label-width="120">
        <FormItem label="账号">
          <Input v-model="formItem.oldMobile" placeholder="请输入账号" style="width:300px"></Input>
        </FormItem>

        <FormItem label="更换手机号">
          <Input v-model="formItem.newMobile" placeholder="请输入您要更换的手机号" style="width:300px"></Input>
        </FormItem>

        <FormItem label="短信验证码">
          <Input v-model="formItem.code" style="width:300px" placeholder="请输入验证码">
          <Button slot="append" type="text" @click="get_authcode" :loading="loading" :disabled='disabled'>{{text}}</Button>
          </Input>
        </FormItem>

        <FormItem style="text-align:center">
          <Button @click="$router.back()">取消</Button>
          <Button type="warning" style="margin-left: 20px" :loading="loading_login" @click="submit">提交</Button>
        </FormItem>
      </Form>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formItem: {},
      loading: false,
      disabled: false,
      text: "获取验证码",
      loading_login: false
    }
  },
  methods: {
    //获取验证码
    get_authcode() {
      //验证手机号格式
      if (/^1[3-9][0-9]{9}$/.test(this.formItem.newMobile)) {
        console.log("go");
        //验证手机号是否已注册
        this.loading = true
        this.app('enterpriseOperators', 'checkRegisterMobile', { mobile: this.formItem.newMobile }).then(res => {
          console.log(res);
          if (res.data.mobileRegister) {
            this.loading = false
            this.$Modal.warning({
              title: res.message,
            })

          } else {
            //未被注册可以发送验证码
            this.app('enterpriseOperators', 'sendRegisterCode', { mobile: this.formItem.newMobile }).then(res => {
              console.log(res);
              this.loading = false
              this.disabled = true
              this.text = '5'
              let clock = setInterval(() => {
                if (this.text) {
                  this.text--
                } else {
                  this.text = '获取验证码'
                  clearInterval(clock)
                  this.disabled = false
                }
              }, 1000);
            })
          }
        })
      } else {
        this.$Modal.warning({
          title: "手机号码格式不正确"
        })
      }
    },
    //提交
    submit() {
      //验证新手机号及验证码格式
      if (/^1[3-9][0-9]{9}$/.test(this.formItem.newMobile) && /^[0-9]{6}$/.test(this.formItem.code)) {
        //验证code
        this.loading_login = true
        //提交 //code正确
        this.app("enterpriseOperators", "changeMobile", this.formItem).then(res => {
          this.$Modal.warning({
            title: res.message
          })
          this.loading_login = false
        })
      } else {
        this.$Modal.warning({
          title: "手机号或验证码格式不正确"
        })
      }
    }
  },
  created() {

  }
}
</script>

<style lang="less" scoped>
.changePhone {
  position: relative;
  height: 100%;
  background: #fff;
  padding: 30px;
  header {
    display: flex;
    align-items: center;
    border-bottom: 10px solid #f5f7f9;
    padding-bottom: 30px;
    .left {
      display: flex;
      align-items: center;
    }
  }
  section {
    width: 50%;
    margin: 0 auto;
    padding-top: 20px;
  }
}
</style>
